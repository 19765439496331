import { useEffect, useRef, useState } from "react";
import { getTreeFromRef, NODE_TYPES, SUB_TYPES } from "sutro-common";
import {
  ConditionalInterpreterAction,
  INTERPRETER_ACTION_TYPES,
  InterpreterActions,
  NavigationInterpreterAction,
} from "sutro-common/client-app/actions";
import {
  InterpreterTree,
  InterpreterTreeRefs,
  isTreeRef,
} from "sutro-common/client-app/tree";
import {
  Edge as VisNetworkEdge,
  Network,
  Node as VisNetworkNode,
} from "vis-network";

import { useStudio } from "~/lib/hooks/use-studio";

export const GraphViewer = () => {
  const container = useRef<HTMLDivElement>(null);
  const { sClientScript } = useStudio();
  const [_, setNetwork] = useState<Network | undefined>();

  useEffect(() => {
    if (sClientScript != null && container.current) {
      const { edges, nodes } = getNodesAndEdges(
        sClientScript.tree,
        sClientScript.treeRefs
      );
      const network = new Network(
        container.current,
        { nodes, edges },
        { interaction: { hover: true, tooltipDelay: 300 } }
      );
      setNetwork(network);
    }
  }, [container, sClientScript]);

  return (
    <div className="flex size-full flex-col items-center overflow-hidden pb-5">
      <div ref={container} className="size-full" />
    </div>
  );
};

const getNodesAndEdges = (
  tree: InterpreterTree,
  treeRefs: InterpreterTreeRefs
) => {
  const { nodes, edges } = getNodes(tree, treeRefs);

  // We could filter based on whether it exists within the edges
  const filteredNodes = nodes.filter(() => true);

  return { nodes: filteredNodes, edges };
};

const getNodes = (
  tree: InterpreterTree,
  treeRefs: InterpreterTreeRefs,
  currentPage?: string
) => {
  const nodes: VisNetworkNode[] = [];
  const edges: VisNetworkEdge[] = [];

  tree.containsTrees?.forEach((t) => {
    const innerTree: InterpreterTree = isTreeRef(t)
      ? getTreeFromRef(t, treeRefs)
      : t;
    let newCurrentPage: string | undefined = currentPage;
    if (
      innerTree.type === NODE_TYPES.CONTAINER_NODE &&
      innerTree.subTypes?.includes(SUB_TYPES.SCREEN) &&
      innerTree.props?.options?.title !== undefined
    ) {
      pushNodes(nodes, [
        {
          id: innerTree.props.name,
          label: innerTree.props.options.title,
          title: innerTree.props.name,
        },
      ]);
      newCurrentPage = innerTree.props.name;
    }

    const navigationActions = findNavigationActions([
      ...(innerTree.clickShouldExecuteActions ?? []),
      ...(innerTree.changeShouldExecuteActions ?? []),
    ]);
    const newEdges: VisNetworkEdge[] = navigationActions.map((a) => {
      return {
        to: a.navigate?.screenName,
        from: currentPage,
        arrows: "to",
        title: innerTree.props?.label,
      };
    });
    pushEdges(edges, newEdges);

    const results = getNodes(innerTree, treeRefs, newCurrentPage);

    pushNodes(nodes, results.nodes);
    pushEdges(edges, results.edges);
  });

  return { edges, nodes };
};

const pushEdges = (edges: VisNetworkEdge[], newEdges: VisNetworkEdge[]) => {
  newEdges.forEach((ne) => {
    if (edges.find((e) => e.to === ne.to && e.from === ne.from) === undefined) {
      edges.push(ne);
    }
  });
};

const pushNodes = (nodes: VisNetworkNode[], newNodes: VisNetworkNode[]) => {
  newNodes.forEach((nn) => {
    if (nodes.find((n) => n.id === nn.id) === undefined) {
      nodes.push(nn);
    }
  });
};

const findNavigationActions = (actions: InterpreterActions) => {
  const navigationActions: NavigationInterpreterAction[] = [];

  navigationActions.push(
    ...actions.filter(
      (a): a is NavigationInterpreterAction =>
        a.type === INTERPRETER_ACTION_TYPES.NAVIGATION &&
        // The "go back" type isn't supported yet
        a.navigate?.screenName !== undefined
    )
  );

  navigationActions.push(
    ...actions
      .map((a) => {
        return findNavigationActions([
          ...(a.onDoneExecuteActions ?? []),
          ...(a.onFailureExecuteActions ?? []),
          ...(a.onSuccessExecuteActions ?? []),
        ]);
      })
      .flat()
  );

  navigationActions.push(
    ...actions
      .filter(
        (a): a is ConditionalInterpreterAction =>
          a.type === INTERPRETER_ACTION_TYPES.CONDITIONAL
      )
      .map((a) => {
        return findNavigationActions([
          ...(a.onFalseInterpreterActions ?? []),
          ...a.onTrueInterpreterActions,
        ]);
      })
      .flat()
  );

  return navigationActions;
};
