import * as React from "react";

import { cn } from "~/lib/utils";

import { TooltipWrapper } from "../../app/tooltip-wrapper";
import { ErrorMessage } from "../error-message";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  disabledTooltip?: React.ReactNode;
  error?: string;
  errorTooltip?: React.ReactNode;
  iconLeft?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      disabledTooltip,
      type,
      error,
      errorTooltip,
      containerClassName,
      iconLeft,
      ...props
    },
    ref
  ) => {
    return (
      <TooltipWrapper
        wrapperClassName={cn("flex flex-col", containerClassName)}
        tooltip={disabledTooltip || errorTooltip || ""}
        disabled={!disabledTooltip && !errorTooltip}
      >
        <div className="relative">
          <input
            type={type}
            className={cn(
              "flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm font-normal text-black ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:text-gray-400 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
              {
                "border-red-600": error || errorTooltip,
                "pl-10": iconLeft,
              },
              className
            )}
            ref={ref}
            {...props}
          />
          {iconLeft && (
            <div className="absolute left-3 top-1/2 flex -translate-y-1/2 items-center [&>svg]:size-5">
              {iconLeft}
            </div>
          )}
        </div>
        <ErrorMessage error={error} />
      </TooltipWrapper>
    );
  }
);
Input.displayName = "Input";

export { Input };
