import { Button } from "../button";

export type UpsellMessageProps = {
  variant?: "simple" | "detailed";
  message: string;
  buttonLabel?: string;
  onClickButton: () => void;
};

const UpsellMessage = ({
  variant = "simple",
  message,
  buttonLabel = "Upgrade",
  onClickButton,
}: UpsellMessageProps) => {
  return (
    <div className="border-color-border flex w-full items-center justify-between rounded-lg border px-4 py-3 shadow-sm">
      <span className="text-color-text text-sm font-medium">{message}</span>
      <Button
        variant="outline"
        className="text-warning border-warning hover:text-warning"
        onClick={onClickButton}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export { UpsellMessage };
