import { AnyObject } from "sutro-common/object-types";

import config from "~/app.config";

import { getCookie, setCookie } from "./cookies";

/**
 * Interacting with local storage gets a little tricky when you're server-side rendering
 * so we have a few helper functions to make it easier
 */

export const retrieveFromLocalStorage = <O extends AnyObject>(
  key: string
): O | null => {
  if (typeof window === "undefined") {
    console.warn(
      "Sutro: Tried to retrieve from local storage on the server. This is may be a mistake, but it's not always a problem"
    );
    return null;
  }
  const storedInfo = window.localStorage.getItem(key);
  if (storedInfo === null) {
    return null;
  }
  try {
    return JSON.parse(storedInfo) as O;
  } catch (e) {
    console.warn(`There was a problem getting ${key}`);
    window.localStorage.removeItem(key);
    return null;
  }
};

export const storeInLocalStorage = <O extends AnyObject>(
  o: O,
  key: string
): void => {
  if (typeof window === "undefined") {
    console.warn(
      "Sutro: Tried to store to local storage on the server. This is usually a mistake"
    );
    return;
  }
  const json = JSON.stringify(o);
  window.localStorage.setItem(key, json);
};

export const clearFromLocalStorage = (key: string) =>
  window.localStorage.removeItem(key);

export const retrieveHasUserVisitedStudio = () =>
  retrieveFromLocalStorage<{ hasVisitedStudio: boolean }>("sutro:user-info")
    ?.hasVisitedStudio ?? false;
export const storeHasUserVisitedStudio = (hasVisitedStudio: boolean) =>
  storeInLocalStorage({ hasVisitedStudio }, "sutro:user-info");
export const clearHasUserVisitedStudio = () =>
  clearFromLocalStorage("sutro:user-info");

export const retrieveUserEmailForAuth = (): string | null => {
  return (
    retrieveFromLocalStorage<{ email: string }>("sutro:auth-user-email")
      ?.email ?? null
  );
};

export const storeUserEmailForAuth = (email: string): void => {
  storeInLocalStorage({ email }, "sutro:auth-user-email");
};

/**
 * We're keeping track of which apps have had a feedback form filled out
 * so we can decide whether to show the feedback toast message
 */
export const retrieveAppFeedbackMap = () => {
  const appFeedbackMap = retrieveFromLocalStorage<{
    [productId: string]: boolean;
  }>("sutro:app-feedback-map");
  return appFeedbackMap ?? {};
};

export const storeAppFeedbackMap = (appFeedbackMap: {
  [productId: string]: boolean;
}) => {
  storeInLocalStorage(appFeedbackMap, "sutro:app-feedback-map");
};

/**
 * If we don't keep this in storage then we'll be polluted with annonymous
 * posthog identities.
 */
const SUTRO_COOKIE_ID_KEY = "sutroId";
export const retrievePosthogIdentityAlias = (): { id: string } | null => {
  let id: { id: string } | null = null;

  // We only do this on Prod because they're all the same domain
  if (typeof document !== "undefined" && !config.isDev) {
    const idVal = getCookie(SUTRO_COOKIE_ID_KEY);
    if (idVal) {
      id = { id: idVal };
    }
  }

  // Backup local storage
  if (id === null) {
    id = retrieveFromLocalStorage<{ id: string }>("sutro:id");
  }
  return id;
};
export const storePosthogIdentityAlias = (posthogIdentityAlias: string) => {
  storeInLocalStorage<{ id: string }>({ id: posthogIdentityAlias }, "sutro:id");

  // We only do this on Prod because they're all the same domain
  if (typeof document !== "undefined" && !config.isDev) {
    setCookie(SUTRO_COOKIE_ID_KEY, posthogIdentityAlias, {
      daysForExpiration: 360,
    });
  }
};
