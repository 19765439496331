import { NavLink } from "@remix-run/react";
import {
  BookOpen,
  ChevronDown,
  Database,
  HelpCircle,
  MessagesSquare,
  Palette,
  Play,
  Puzzle,
  Save,
  Settings,
} from "lucide-react";
import { CSSProperties, ReactNode, useCallback, useState } from "react";
import { toast } from "sonner";
import {
  ButtonIds,
  DropdownIds,
  FormIds,
  StudioEventTypes,
} from "sutro-analytics";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";

import logo from "~/assets/sutro.svg";
import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Popover, PopoverTrigger } from "~/components/ui/popover";
import useBreakpoints from "~/lib/hooks/use-breakpoint";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { useStudioDialog } from "~/lib/hooks/use-studio-dialog";
import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import { useAnalytics } from "~/lib/use-analytics";
import { useProfile } from "~/lib/use-profile";
import { cn } from "~/lib/utils";
import { useIntercom } from "~/providers/IntercomProvider/intercom-provider";

import { DEV_TOOLS_DIALOG_ID, DevToolsNavButton } from "../devtools/dev-tools";
import { PosthogNavButton } from "../devtools/posthog-nav-button";
import { FeatureRequestDialog } from "../feature-request-dialog";
import { PublishPopover } from "../publish/publish-popover";
import { usePublish } from "~/lib/use-publish";

function TopCreatorLink({
  to,
  label,
  icon,
}: {
  to: string;
  label: string;
  icon: ReactNode;
}) {
  return (
    <NavLink
      replace
      to={to}
      end={to === ""}
      className={({ isActive }) =>
        cn(
          "hover:bg-bg-elevated text-text-muted flex items-center justify-center gap-1 rounded-md p-2 transition-colors hover:text-black",
          {
            "bg-bg-elevated text-black": isActive,
          }
        )
      }
    >
      {icon}
      <span className="text-xs">{label}</span>
    </NavLink>
  );
}

export function BelowNavigationBarFullSizeContainer({
  children,
  className,
  style,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      className={cn("flex h-[calc(100%-68px)] justify-center", className)}
      style={style}
    >
      <div className="size-full bg-bg-subtle">{children}</div>
    </div>
  );
}

function SutroLogoDropdown() {
  const { logout } = useProfile();
  const { track } = useAnalytics();

  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (open) {
          track(StudioEventTypes.DROPDOWN_CLICK, {
            dropdownId: DropdownIds.DESIGNER_TOP_NAV_SUTRO_DROPDOWN,
          });
        }
      }}
    >
      <DropdownMenuTrigger
        data-testId={DropdownIds.DESIGNER_TOP_NAV_SUTRO_DROPDOWN}
      >
        <div className="border-border flex items-center gap-2 rounded-md border px-4 py-2 cursor-pointer">
          <SutroLogo />
          <ChevronDown className="text-text-muted size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <NavLink to="/">
          <DropdownMenuItem className="cursor-pointer">
            <span>Go to dashboard</span>
          </DropdownMenuItem>
        </NavLink>
        <NavLink to="/user" target="_blank">
          <DropdownMenuItem className="cursor-pointer">
            <span>User settings</span>
          </DropdownMenuItem>
        </NavLink>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => logout()}
          data-testId={ButtonIds.LOGOUT}
        >
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SutroLogo() {
  return (
    <img src={logo} className="h-5 text-white select-none" alt="Sutro logo" />
  );
}

function AssistanceMenu() {
  const authApi = SutroApi.getApi().authenticate();
  const { track } = useAnalytics();
  const [isFeatureRequestDialogOpen, setIsFeatureRequestDialogOpen] =
    useState(false);
  const intercom = useIntercom();

  const openIntercom = useCallback(() => {
    track(StudioEventTypes.BUTTON_CLICK, {
      buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_NEED_HELP,
    });
    intercom("show");
  }, [intercom]);

  const submitFeatureRequest = useCallback(
    (featureRequest: { subject: string; description: string }) => {
      const throwError = () => {
        toast.error("Failed to submit feature request");
        track(StudioEventTypes.ERROR, {
          message: "Failed to submit feature request",
        });
      };

      void authApi.post("/submitFeatureRequest", featureRequest).then(
        either(() => {
          toast.success("Feature request submitted successfully");
          track(StudioEventTypes.FORM_SUBMIT, {
            formId: FormIds.FEATURE_REQUEST,
          });
        }).or(throwError)
      );
    },
    [authApi]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          testId={ButtonIds.DESIGNER_TOP_NAV_ASSISTANCE_MENU}
        >
          <HelpCircle className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start"
          onClick={() => {
            setIsFeatureRequestDialogOpen(true);
            track(StudioEventTypes.BUTTON_CLICK, {
              buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_FEATURE_REQUEST,
            });
          }}
        >
          <Puzzle className="size-5" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Request a feature</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start"
          onClick={openIntercom}
        >
          <MessagesSquare className="size-5" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Chat with support</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start"
          onClick={() => {
            track(StudioEventTypes.BUTTON_CLICK, {
              buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_READ_DOCS,
            });
          }}
        >
          <BookOpen className="size-5" />
          <a
            href="https://docs.withsutro.com/"
            target="_blank"
            className="flex flex-col pl-3"
            rel="noreferrer noopener"
          >
            <span className="text-sm">View help guides</span>
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>

      <FeatureRequestDialog
        open={isFeatureRequestDialogOpen}
        onOpenChange={setIsFeatureRequestDialogOpen}
        onSubmit={submitFeatureRequest}
      />
    </DropdownMenu>
  );
}

export function StandardTopNavigationBar() {
  return (
    <TopNavigationBar>
      <SutroLogoDropdown />
      <div className="flex grow items-center justify-center self-stretch py-3">
        {/* Not needed right now */}
        {/* <SearchBar /> */}
      </div>
      <AssistanceMenu />
    </TopNavigationBar>
  );
}

export function CreatorTopNavigationBar() {
  return (
    <TopNavigationBar containerClassName="justify-between">
      <SutroLogoDropdown />
      <AssistanceMenu />
    </TopNavigationBar>
  );
}

export function StudioTopNavigationBar() {
  const executeSave = useStudio((s) => s.executeSave);
  const flags = useFeatureFlags(
    PostHogFlags.DisableAutosave,
    PostHogFlags.PublishApp,
    PostHogFlags.ActionsTabVisible
  );
  const [, openDevToolsDialog] = useStudioDialog(DEV_TOOLS_DIALOG_ID);
  const publishPopoverOpen = usePublish((s) => s.publishPopoverOpen);
  const setPublishPopoverOpen = usePublish((s) => s.setPublishPopoverOpen);
  const { track } = useAnalytics();
  const breakpoints = useBreakpoints();

  const links: {
    label: string;
    icon: ReactNode;
    to: string;
  }[] = [
    {
      label: "Design",
      icon: <Palette className="size-4" />,
      to: "design",
    },
    {
      label: "Data",
      icon: <Database className="size-4" />,
      to: "data",
    },
    ...(flags[PostHogFlags.ActionsTabVisible]
      ? [
          {
            label: "Actions",
            icon: <Play className="size-4" />,
            to: "actions",
          },
        ]
      : []),
    {
      label: "Settings",
      icon: <Settings className="size-4" />,
      to: "manage",
    },
  ];

  return (
    <TopNavigationBar>
      <SutroLogoDropdown />
      <div className="flex grow items-center justify-center self-stretch py-3">
        <div className="flex items-center gap-1">
          {links.map((link) => (
            <TopCreatorLink
              key={link.label}
              icon={link.icon}
              to={link.to}
              label={link.label}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <PosthogNavButton />
        <DevToolsNavButton onClick={openDevToolsDialog} />
        {flags[PostHogFlags.PublishApp] && (
          <Popover
            open={publishPopoverOpen}
            onOpenChange={(open) => {
              track(StudioEventTypes.BUTTON_CLICK, {
                buttonId: ButtonIds.DESIGNER_TOP_NAV_PUBLISH,
              });

              setPublishPopoverOpen(open);
            }}
          >
            <PopoverTrigger>
              <Button>Publish</Button>
            </PopoverTrigger>
            <PublishPopover />
          </Popover>
        )}
        {flags[PostHogFlags.DisableAutosave] && (
          <Button
            onClick={() => {
              void executeSave();
            }}
            className="flex flex-row gap-2"
          >
            <Save className="size-4" />
            {breakpoints.lg && "Save"}
          </Button>
        )}
        <AssistanceMenu />
      </div>
    </TopNavigationBar>
  );
}

function TopNavigationBar({
  children,
  wrapperClassName,
  containerClassName,
}: {
  children: ReactNode;
  wrapperClassName?: string;
  containerClassName?: string;
}) {
  return (
    <div
      className={cn(
        "border-border sticky top-0 z-50 flex h-[52px] w-full justify-center border-b bg-white",
        wrapperClassName
      )}
    >
      <div className={cn("flex w-full items-center px-4", containerClassName)}>
        {children}
      </div>
    </div>
  );
}
