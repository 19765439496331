import "./sonner.css";
import "./tailwind.css";

import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import * as Sentry from "@sentry/browser";
import posthogJs from "posthog-js";
import { PropsWithChildren, Suspense, useEffect, useMemo, useRef } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { UTM_COOKIE_ID } from "sutro-common/cookie-ids";
import { reportErrorEvent } from "sutro-common/errors/report-sutro-error";
import { addPrefixToKeys } from "sutro-common/object-operations";
import { OpenTelemetry } from "sutro-common/observability/OpenTelemetry";
import { useEventListener } from "usehooks-ts";

import config from "~/app.config";
import { FullPageLoader } from "~/components/app/full-page-loader";
import { PageContent } from "~/components/app/page-content";
import { PageLoadingIndicator } from "~/components/app/page-loading-indicator";
import { PageStatus } from "~/components/app/page-status";
import {
  BelowNavigationBarFullSizeContainer,
  StandardTopNavigationBar,
} from "~/components/app/top-navigation-bar";
import { Toaster } from "~/components/ui/sonner";
import { setCookie } from "~/lib/cookies";
import { useAppConfig } from "~/lib/hooks/use-app-config";
import { WindowSizeProvider } from "~/lib/hooks/use-breakpoint";
import { GLOBAL_KEYMAP } from "~/lib/hot-keys/global-keymap";
import { extractUtmTags, useAnalytics } from "~/lib/use-analytics";
import { PreviewCommLayerProvider } from "~/lib/use-preview-comm";
import { useProfile } from "~/lib/use-profile";
import { EntitlementsProvider } from "~/providers/EntitlementsProvider/entitlements-provider";
import { IntercomProvider } from "~/providers/IntercomProvider/intercom-provider";
import { TierManagementProvider } from "~/providers/TierManagementProvider";

import { isStudioError } from "./lib/studio-error";

export const links: LinksFunction = () => {
  return [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/svg+xml",
      href: "/favicon.svg",
    },
    {
      rel: "shortcut icon",
      href: "/favicon.ico",
    },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#006666" },
    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
    },
  ];
};

export const meta: MetaFunction = () => {
  return [
    { name: "msapplication-TileColor", content: "#006666" },
    { name: "theme-color", content: "#ffffff" },
    { name: "apple-mobile-web-app-title", content: "Sutro" },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body suppressHydrationWarning>
        <PageLoadingIndicator />
        <div className="size-full">{children}</div>
        {/* The z-index here should be less than the z-index of dialog.tsx, since we don't want toasts to appear above dialogs */}
        <Toaster className="z-40" />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

const PUBLIC_URLS = [
  "/login",
  "/create",
  "/signup",
  "/reset-password",
  "/change-password",
];

export const entryRouteIfLoggedOut = "/signup";

export default function App() {
  const { currentUser, loadingUserDetails, logout } = useProfile();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { pageview } = useAnalytics();
  const { setAppConfig } = useAppConfig();
  const location = useLocation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (loadingUserDetails) {
      return;
    }

    const utmTags = extractUtmTags(new URLSearchParams(location.search));
    if (Object.keys(utmTags).length > 0) {
      setCookie(UTM_COOKIE_ID, utmTags);
    }
    if (!currentUser && !PUBLIC_URLS.includes(pathname)) {
      navigate(entryRouteIfLoggedOut, { replace: true });
    }
    pageview();
  }, [
    currentUser,
    location.search,
    navigate,
    pageview,
    pathname,
    loadingUserDetails,
  ]);

  // This occurs when something happens that
  // clears out local storage, putting the page into an inconsistent state
  useEventListener("sutro:force-logout", () => {
    logout(() => navigate("/login", { replace: true }));
  });

  useEventListener("error", reportErrorEvent(isStudioError));
  useEventListener("unhandledrejection", reportErrorEvent(isStudioError));

  const handlers: { [key: string]: (keyEvent?: KeyboardEvent) => void } =
    useMemo(() => {
      return {
        ENABLE_DEVELOPER_OPTIONS: (event) => {
          event?.preventDefault();
          setAppConfig({ isDev: true });
        },
      };
    }, [setAppConfig]);

  return (
    <WindowSizeProvider>
      <IntercomProvider appId={config.intercomAppId}>
        <OpenTelemetry
          serviceName={config.otelServiceName}
          endpoint={config.otelEndpoint}
        />
        <IdentityWrapper>
          <PreviewCommLayerProvider iframeRef={iframeRef}>
            <EntitlementsProvider>
              <TierManagementProvider>
                <GlobalHotKeys keyMap={GLOBAL_KEYMAP} handlers={handlers}>
                  <Outlet />
                </GlobalHotKeys>
              </TierManagementProvider>
            </EntitlementsProvider>
          </PreviewCommLayerProvider>
        </IdentityWrapper>
      </IntercomProvider>
    </WindowSizeProvider>
  );
}

function IdentityWrapper({ children }: PropsWithChildren) {
  const { currentUser, posthogIdentityAlias } = useProfile();

  useEffect(
    () => {
      if (currentUser) {
        posthogJs.identify(currentUser.id, {
          username: currentUser.identity,
          ...addPrefixToKeys("user.", {
            ...currentUser,
            avatar: undefined,
          }),
        });
        posthogJs.register({ email: currentUser.identity });
      } else {
        posthogJs.identify(posthogIdentityAlias);
        posthogJs.unregister("email");
      }
    },
    // Run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return children;
}

export function HydrateFallback() {
  return <FullPageLoader />;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const is404 = isRouteErrorResponse(error) && error.status === 404;

  useEffect(() => {
    if (error && !is404) {
      Sentry.captureException(error);
    }
  }, [error, is404]);

  return (
    <Suspense>
      <WindowSizeProvider>
        <IdentityWrapper>
          <OpenTelemetry
            serviceName={config.otelServiceName}
            endpoint={config.otelEndpoint}
          />
          <StandardTopNavigationBar />
          <BelowNavigationBarFullSizeContainer>
            <PageContent className="flex h-full items-center justify-center">
              <PageStatus
                title={is404 ? "404 - Site not found" : "Something went wrong"}
                message={
                  is404
                    ? "Oops! The site you’re looking for was not found."
                    : "We could not process your last request."
                }
              />
            </PageContent>
          </BelowNavigationBarFullSizeContainer>
        </IdentityWrapper>
      </WindowSizeProvider>
    </Suspense>
  );
}
