import { GetBundleForStudioRequestBody } from "sutro-common";
import { BundleForStudio } from "sutro-common/client-app/compilation-outputs";

import { SutroApi } from "./sutro-api";
import { StudioHttpError } from "./sutro-api/StudioHttpError";

type BundleResponse = {
  bundleForStudio?: BundleForStudio;
  error?: StudioHttpError;
  status?: number;
};

export default async function getBundleForStudio(
  body: GetBundleForStudioRequestBody,
  signal?: AbortSignal
): Promise<BundleResponse> {
  try {
    const bundleResponse = await SutroApi.getApi().authenticate().post<{
      bundleForStudio: BundleForStudio;
    }>(`/getBundleForStudioAndStartTempServer`, body, {
      signal,
    });

    return bundleResponse.map<BundleResponse>(
      (error) => ({
        bundleForStudio: undefined,
        error,
        status: error instanceof StudioHttpError ? error.status : 0,
      }),
      ({ bundleForStudio }) => ({
        bundleForStudio,
        error: undefined,
        status: 200,
      })
    );
  } catch (e) {
    // This should only fail rarely, usually things like client interrupt
    return {
      bundleForStudio: undefined,
      error: new StudioHttpError(-1, "Failed to load"),
      status: undefined,
    };
  }
}
