import { useCallback, useEffect, useState } from "react";
import { TIER_NAMES, TierName } from "sutro-common/studio-entitlements";

import { getCustomTierPricingData, PRICING_TIERS } from "~/data/pricing-tiers";
import useBreakpoints from "~/lib/hooks/use-breakpoint";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";
import { PricingTier } from "./pricing-tier";

type Props = {
  heading: string;
  subHeading: string;
  recommendedTier?: TierName;
  changingToTier: TierName | null;
  onChangeTier: (
    tier: TierName,
    onComplete: () => Promise<void>,
    onCancel: () => Promise<void>
  ) => Promise<void>;
  showFreePlan?: boolean;
};

export function PricingTable({
  recommendedTier = "Grow",
  heading,
  subHeading,
  changingToTier,
  onChangeTier,
  showFreePlan = false,
}: Props) {
  const { currentSubscription, refreshEntitlements, entitlements } =
    useEntitlements();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const { md } = useBreakpoints();

  const isTabletOrSmaller = !md;

  // Scroll to the recommended tier on Mobile
  useEffect(() => {
    if (isTabletOrSmaller) {
      carouselApi?.scrollNext(true);
    }
  }, [carouselApi, isTabletOrSmaller]);

  /**
   * The "tier rank" is a way to determine whether moving from the current tier to another one is an upgrade or downgrade
   *
   * If the tier rank is -1, then that means that the current tier is a custom tier
   */
  const currentTierRank = TIER_NAMES.indexOf(currentSubscription.tier.name);
  const currentTierIsCustom = currentTierRank < 0;

  // Filter out the Free tier if it's not being shown
  const pricingTiers = [...PRICING_TIERS].filter(
    (tier) => tier.tierName !== "Free" || showFreePlan
  );
  /**
   * By default, we have a "Custom" tier as the last PRICING_TIER.
   * It is essentially a catch-all
   *
   * In the case when we have an _actual_ Custom tier, we replace the
   * catch-all with the real one
   */
  if (currentTierIsCustom) {
    pricingTiers.pop();
    pricingTiers.push(
      getCustomTierPricingData(currentSubscription.tier, entitlements)
    );
  }

  const onUpdateComplete = useCallback(async () => {
    await refreshEntitlements();
  }, [refreshEntitlements]);

  const onUpdateCancel = useCallback(() => {
    return Promise.resolve();
  }, []);

  const updateSubscription = useCallback(
    async (newTier: TierName) => {
      await onChangeTier(newTier, onUpdateComplete, onUpdateCancel);
    },
    [onChangeTier, onUpdateCancel, onUpdateComplete]
  );
  return (
    <div className="flex w-full min-w-0 max-w-full flex-col p-2">
      <div className="mb-4 flex flex-col items-center gap-2">
        <h2 className="text-2xl font-semibold leading-5">{heading}</h2>
        <p className="text-text-muted text-sm">{subHeading}</p>
      </div>
      <Carousel
        opts={{
          slidesToScroll: 1,
          align: "start",
          // Disable drag on tablet or smaller devices
          watchDrag: isTabletOrSmaller,
        }}
        setApi={setCarouselApi}
      >
        <CarouselContent className="w-full max-w-full p-2">
          {pricingTiers.map((tier, index) => {
            const thisTierIsCurrentTier =
              currentSubscription.tier.name === tier.tierName;

            let actionDescription = "Selected";
            if (TIER_NAMES.includes(tier.tierName)) {
              const comparativeRank = index - currentTierRank;
              if (currentTierIsCustom) {
                actionDescription = "Contact Us";
              } else if (comparativeRank > 0) {
                actionDescription = "Upgrade to " + tier.tierName;
              } else if (comparativeRank < 0) {
                actionDescription = "Downgrade to " + tier.tierName;
              }
            }

            if (!thisTierIsCurrentTier && tier.tierName === "Custom") {
              actionDescription = "Contact Us";
            }

            /**
             * We only display a plan as recommended if it is not the user's current plan
             * and if the user is not currently on a custom plan
             */
            const recommended =
              tier.tierName === recommendedTier &&
              !thisTierIsCurrentTier &&
              !currentTierIsCustom;

            /**
             * We disable the action button if:
             * - The user is already on this tier
             * - The user is currently changing their tier
             * - The user is on a custom tier
             */
            const disabled =
              thisTierIsCurrentTier ||
              changingToTier !== null ||
              currentTierIsCustom;

            return (
              <CarouselItem
                className="basis-full sm:basis-1/2 md:basis-1/3 lg:basis-1/4"
                key={tier.tierName}
              >
                <PricingTier
                  {...tier}
                  recommended={recommended}
                  currentPlan={thisTierIsCurrentTier}
                  actionDisabled={disabled}
                  actionDescription={actionDescription}
                  action={() => updateSubscription(tier.tierName)}
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <div className="mt-8 flex items-center justify-center gap-2 lg:hidden">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      </Carousel>
    </div>
  );
}
