/**
 * This will be passed in to `toast.custom()` from `sonner`
 */

export const SuccessPublishToast = ({
  domainHref,
  domainLabel,
}: {
  domainHref: string;
  domainLabel: string;
}) => {
  return (
    <div className="border-success bg-success-muted flex items-center rounded-md border p-4 shadow-lg">
      <span className="text-text-success text-sm font-normal">
        Published to{" "}
        <a
          href={domainHref}
          className="text-color-text text-sm font-medium hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {domainLabel}
        </a>
      </span>
    </div>
  );
};

export const PlainPublishToast = ({ text }: { text: string }) => {
  return (
    <div className="border border-border bg-white flex items-center rounded-md p-4 shadow-lg">
      <span className="text-color-text text-sm">{text}</span>
    </div>
  );
};
