import { Either } from "@dancrumb/fpish";
import posthogJs from "posthog-js";
import { StudioEventTypes } from "sutro-analytics";
import {
  convertFileToBase64,
  DEVICE_PLATFORMS,
  PublishedAppResponse,
  SutroError,
} from "sutro-common";
import { Product } from "sutro-common/sutro-data-store-types";
import { z } from "zod";
import { create } from "zustand";

import { SutroApi } from "~/lib/sutro-api";

export enum PublishRequestStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  QUEUED = "QUEUED",
  FULFILLED = "FULFILLED",
}

export enum UnpublishRequestStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

export enum AppleTeamType {
  COMPANY_OR_ORGANIZATION = "COMPANY_OR_ORGANIZATION",
  INDIVIDUAL = "INDIVIDUAL",
}

export const appPublishSchema = z.object({
  appIconImage: z.object({
    image: z.string(),
    height: z.number(),
    width: z.number(),
  }),
  platform: z.nativeEnum(DEVICE_PLATFORMS),
  expoOrganizationName: z.string().min(1, "Organization name is required"),
  expoPersonalAccessToken: z
    .string()
    .min(1, "Personal access token is required"),
  expoProjectId: z.string().min(1, "Expo project id is required"),
  expoProjectSlug: z.string().min(1, "Expo project slug is required"),
  privacyPolicyLink: z.string().url().optional(),
  termsOfServiceLink: z.string().url().optional(),
  splashScreenImage: z
    .object({
      image: z.string(),
      height: z.number(),
      width: z.number(),
    })
    .optional(),
  ascApiKeyFile: z
    .instanceof(File)
    .refine((file) => file.name.endsWith(".p8"), {
      message: "ASC API key file must be a .p8 file",
    }),
  ascKeyId: z.string().min(1, "Key identifier is required"),
  ascIssuerId: z.string().min(1, "Issuer identifier is required"),
  appleTeamId: z.string().min(1, "Apple Team ID is required"),
  appleTeamType: z.nativeEnum(AppleTeamType),
});

export type PublishFormType = z.infer<typeof appPublishSchema>;

type MobilePlatforms = DEVICE_PLATFORMS.IOS | DEVICE_PLATFORMS.ANDROID;
interface PublishState {
  currentStep: number;
  publishedProducts: Product[];
  fetchPublishedProducts: () => Promise<void>;
  setCurrentStep: (step: number) => void;
  hasTermsOfServiceAndPrivacyPolicy: boolean;
  setHasTermsOfServiceAndPrivacyPolicy: (has: boolean) => void;
  hasPlaystoreDevAccount: boolean | undefined;
  setHasPlaystoreDevAccount: (has: boolean | undefined) => void;
  hasAppleDevAccount: boolean | undefined;
  setHasAppleDevAccount: (has: boolean | undefined) => void;
  executePublish: (
    formValues: PublishFormType & {
      productId: Product["id"];
    }
  ) => Promise<PublishedAppResponse>;
  executeUnpublish: (
    productId: Product["id"],
    platform: DEVICE_PLATFORMS
  ) => Promise<unknown>;
  publishRequestStatus: PublishRequestStatus;
  publishRequestError: string | null;
  publishRequestResult: PublishedAppResponse | null;
  unpublishRequestStatus: UnpublishRequestStatus;
  unpublishRequestError: string | null;
  setPublishRequestStatus: (status: PublishRequestStatus) => void;
  setPublishRequestError: (error: string | null) => void;
  setPublishRequestResult: (result: PublishedAppResponse | null) => void;
  setUnpublishRequestStatus: (status: UnpublishRequestStatus) => void;
  setUnpublishRequestError: (error: string | null) => void;
  uploadAppStaticAssets: (
    assets: {
      name: string;
      file?:
        | PublishFormType["appIconImage"]
        | PublishFormType["splashScreenImage"];
    }[],
    {
      productId,
      platform,
    }: { productId: Product["id"]; platform: MobilePlatforms }
  ) => void;
  publishPopoverOpen: boolean;
  setPublishPopoverOpen: (open: boolean) => void;
}

const initialState = {
  publishPopoverOpen: false,
  currentStep: 1,
  publishedProducts: [],
  hasTermsOfServiceAndPrivacyPolicy: false,
  hasPlaystoreDevAccount: undefined,
  hasAppleDevAccount: undefined,
  publishRequestError: null,
  publishRequestResult: null,
  publishRequestStatus: PublishRequestStatus.IDLE,
  unpublishRequestStatus: UnpublishRequestStatus.IDLE,
  unpublishRequestError: null,
};

export const usePublish = create<PublishState>((set, get) => ({
  ...initialState,
  fetchPublishedProducts: async () => {
    const response = await SutroApi.getApi()
      .authenticate()
      .get<{ products: Product[] }>("/products/published");

    if (response.isRight()) {
      set({ publishedProducts: response.getRight().products });
    }
  },
  setCurrentStep: (step: number) => set({ currentStep: step }),
  setHasTermsOfServiceAndPrivacyPolicy: (has: boolean) =>
    set({ hasTermsOfServiceAndPrivacyPolicy: has }),
  setHasPlaystoreDevAccount: (has: boolean | undefined) =>
    set({ hasPlaystoreDevAccount: has }),
  setHasAppleDevAccount: (has: boolean | undefined) =>
    set({ hasAppleDevAccount: has }),
  setPublishRequestStatus: (status: PublishRequestStatus) =>
    set({ publishRequestStatus: status }),
  setPublishRequestError: (error: string | null) =>
    set({ publishRequestError: error }),
  setPublishRequestResult: (result: PublishedAppResponse | null) =>
    set({ publishRequestResult: result }),
  setUnpublishRequestStatus: (status: UnpublishRequestStatus) =>
    set({ unpublishRequestStatus: status }),
  setUnpublishRequestError: (error: string | null) =>
    set({ unpublishRequestError: error }),
  uploadAppStaticAssets: async (
    assets: {
      name: string;
      file?:
        | PublishFormType["appIconImage"]
        | PublishFormType["splashScreenImage"];
    }[],
    {
      productId,
      platform,
    }: { productId: Product["id"]; platform: MobilePlatforms }
  ) => {
    try {
      const formData = new FormData();

      for (const asset of assets) {
        if (asset.file) {
          const imageBlob = await fetch(asset.file.image).then((res) =>
            res.blob()
          );
          formData.append(
            `${productId}_${platform}_${asset.name}`,
            imageBlob,
            `${asset.name}.png`
          );
        }
      }

      const response = await SutroApi.getApi()
        .authenticate()
        .post(`/products/${productId}/assets`, formData);

      return Either.right(response);
    } catch (error) {
      return Either.left(error);
    }
  },
  /**
   * Initiates the app publishing process with validated form data.
   *
   * This function handles the entire publishing workflow for all platforms (iOS, Android, or Web).
   *
   * @param formValues - The validated form data containing all necessary information for publishing
   * @returns A Promise that resolves when the publishing process is complete
   * @throws Will throw an error if the publishing process fails
   */
  executePublish: async (
    formValues: PublishFormType & {
      productId: Product["id"];
    }
  ) => {
    get().setPublishRequestStatus(PublishRequestStatus.LOADING);

    const {
      appIconImage,
      splashScreenImage,
      productId,
      ...formValuesWithoutImages
    } = formValues;

    const payloadToSend = formValuesWithoutImages;

    /**
     * Convert the ASC API key file to a base64 encoded string
     */
    if (formValues.platform === DEVICE_PLATFORMS.IOS) {
      const ascApiKeyBase64 = await convertFileToBase64(
        formValues.ascApiKeyFile
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error this is typed as a File but we're sending a base64 string, which is intentional
      payloadToSend["ascApiKeyFile"] = ascApiKeyBase64;
    }

    /**
     * Upload the app icon to the server - this is required for both iOS and Android
     * TODO: Right now it doesn't do much error handling. If the upload fails, it'll probably use the default app icon. This needs to be fixed.
     */
    if (
      formValues.platform === DEVICE_PLATFORMS.IOS ||
      formValues.platform === DEVICE_PLATFORMS.ANDROID
    ) {
      get().uploadAppStaticAssets(
        [
          {
            name: "appIcon",
            file: appIconImage,
          },
          {
            name: "appSplashScreen",
            file: splashScreenImage,
          },
        ],
        {
          productId,
          platform: formValues.platform,
        }
      );
    }

    posthogJs.capture(StudioEventTypes.PROJECT_PUBLISH, {
      platform: formValues.platform,
    });

    const publishRequestResponse = await SutroApi.getApi()
      .authenticate()
      .post<PublishedAppResponse>(
        `/products/${productId}/publish`,
        payloadToSend
      );

    return publishRequestResponse.map(
      () => {
        const errorMessage =
          "Something failed in the publish process. Please try again later.";
        get().setPublishRequestStatus(PublishRequestStatus.ERROR);
        get().setPublishRequestError(errorMessage);
        // eslint-disable-next-line sutro/errors-have-static-message
        throw new SutroError(errorMessage);
      },
      (data) => {
        get().setPublishRequestResult(data);
        get().setPublishRequestStatus(PublishRequestStatus.FULFILLED);
        return data;
      }
    );
  },
  executeUnpublish: async (
    productId: Product["id"],
    // TODO: Currently we only support unpublishing apps on web, so we're not making use of the platform param
    platform: DEVICE_PLATFORMS
  ) => {
    get().setUnpublishRequestStatus(UnpublishRequestStatus.LOADING);

    const unpublishRequestResponse = await SutroApi.getApi()
      .authenticate()
      .delete(`/products/${productId}/currentVersion`);

    return unpublishRequestResponse.map(
      () => {
        const errorMessage =
          "Something failed in the unpublish process. Please try again later.";
        get().setUnpublishRequestStatus(UnpublishRequestStatus.ERROR);
        get().setUnpublishRequestError(errorMessage);
        // eslint-disable-next-line sutro/errors-have-static-message
        throw new SutroError(errorMessage);
      },
      (res) => {
        get().setUnpublishRequestStatus(UnpublishRequestStatus.IDLE);
        get().setUnpublishRequestError(null);
        return res;
      }
    );
  },
  setPublishPopoverOpen: (open: boolean) => set({ publishPopoverOpen: open }),
}));
