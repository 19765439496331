import { drop } from "@dancrumb/fpish";
import { AlertCircle, AlertTriangle, Link } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "sonner";
import { ButtonIds } from "sutro-analytics/src/types";
import { DEVICE_PLATFORMS, PostHogFlags } from "sutro-common";
import { pick } from "sutro-common/collection-helpers/pick";
import {
  addProtocolToHostName,
  getSutroAppUrl,
  removeProtocolFromHostName,
} from "sutro-common/hostnames";
import {
  getNextTierName,
  hasEntitlement,
} from "sutro-common/studio-entitlements";
import { Subdomain } from "sutro-common/sutro-data-store-types";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";

import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { SmallLoadingSpinner } from "~/components/ui/loading-spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { UpsellMessage } from "~/components/ui/upsell-message/upsell-message";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { StudioError } from "~/lib/studio-error";
import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import { useProfile } from "~/lib/use-profile";
import {
  appPublishSchema,
  PublishRequestStatus,
  UnpublishRequestStatus,
  usePublish,
} from "~/lib/use-publish";
import { cn } from "~/lib/utils";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import { PlainPublishToast, SuccessPublishToast } from "../publish-toast";

export const DomainManagement = ({
  onRequestUpgrade,
}: {
  onRequestUpgrade: () => void;
}) => {
  const publishMeta = useProfile((s) => s.publishMeta);
  const {
    executePublish,
    publishRequestStatus,
    unpublishRequestError,
    unpublishRequestStatus,
    executeUnpublish,
  } = usePublish(
    useShallow(
      pick(
        "executePublish",
        "publishRequestStatus",
        "setPublishRequestStatus",
        "unpublishRequestError",
        "unpublishRequestStatus",
        "executeUnpublish"
      )
    )
  );
  const fetchPublishMeta = useProfile((s) => s.fetchPublishMeta);
  const { getValues } = useFormContext<z.infer<typeof appPublishSchema>>();
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [isCopyLinkTooltipOpen, setIsCopyLinkTooltipOpen] = useState(false);
  const { entitlements, currentSubscription } = useEntitlements();
  const flags = useFeatureFlags(PostHogFlags.PremiumFeatures);
  const [subdomainError, setSubdomainError] = useState<string | undefined>(
    undefined
  );
  const productId = useStudio((s) => s.product?.id);

  const [currentProductSubdomain, setCurrentProductSubdomain] = useState<
    Pick<Subdomain, "subdomainValue" | "wasGenerated">
  >({
    subdomainValue: "",
    wasGenerated: false,
  });
  const [subdomainInput, setSubdomainInput] = useState("");

  const subdomainChanged =
    currentProductSubdomain.subdomainValue !== subdomainInput;

  const productInPublishMeta = publishMeta?.publishedProducts.find(
    (item) => item.productId === productId
  );

  const isRepublishing = Boolean(productInPublishMeta);

  const fetchSubdomain = useCallback(() => {
    if (productId === undefined) {
      return;
    }

    void SutroApi.getApi()
      .post<{
        data?: { subdomain: Subdomain };
      }>(`/getOrGenerateSubdomain`, {
        productId,
      })
      .then(
        either<{ data?: { subdomain: Subdomain } }>(drop).or(({ data }) => {
          if (data?.subdomain) {
            setCurrentProductSubdomain({
              subdomainValue: data.subdomain.subdomainValue,
              wasGenerated: data.subdomain.wasGenerated,
            });
            setSubdomainInput(data.subdomain.subdomainValue);
          }
        })
      )
      .catch(drop);
  }, [productId]);

  const saveSubdomain = useCallback(async () => {
    setSubdomainError(undefined);
    return new Promise<void>((resolve, reject) => {
      SutroApi.getApi()
        .post<{
          data?: Pick<Subdomain, "wasGenerated" | "subdomainValue">;
        }>(`/claimSubdomain`, {
          productId,
          subdomain: subdomainInput,
        })
        .then(
          either<{
            data?: Pick<Subdomain, "wasGenerated" | "subdomainValue">;
          }>(() => {
            setSubdomainError("Domain unavailable. Please choose another.");
            reject(
              new StudioError("Domain unavailable. Please choose another.")
            );
          }).or(({ data }) => {
            if (data !== undefined) {
              setCurrentProductSubdomain(data);
              setSubdomainInput(data?.subdomainValue);
              resolve();
            } else {
              reject(new StudioError("Unexpected error occurred."));
            }
          })
        )
        .catch((error) => {
          reject(error);
        });
    });
  }, [productId, subdomainInput]);

  useEffect(() => {
    fetchSubdomain();
  }, [fetchSubdomain]);

  const handlePublish = useCallback(async () => {
    try {
      if (productId === undefined) {
        return;
      }

      if (!subdomainInput) {
        setSubdomainError("Domain is required.");
        return;
      }

      await saveSubdomain();

      const domainHref = addProtocolToHostName(
        getSutroAppUrl(subdomainInput, import.meta.env.SUTRO_ENV),
        "https"
      );

      const values = getValues();
      await executePublish({ ...values, productId });

      fetchPublishMeta();

      toast.custom(() => (
        <SuccessPublishToast
          domainHref={domainHref}
          domainLabel={removeProtocolFromHostName(domainHref)}
        />
      ));
    } catch (err) {
      console.error(err);
    }
  }, [
    productId,
    subdomainInput,
    getValues,
    executePublish,
    fetchPublishMeta,
    saveSubdomain,
  ]);

  const handleUnpublish = useCallback(async () => {
    if (productId === undefined) {
      return;
    }

    try {
      await executeUnpublish(productId, DEVICE_PLATFORMS.MOBILE_WEB);
      toast.custom(() => <PlainPublishToast text="Your app was unpublished" />);
    } catch (err) {
      console.error(err);
      toast.custom(() => (
        <PlainPublishToast
          text={
            unpublishRequestError ??
            "Something went wrong during unpublishing. Please try again later"
          }
        />
      ));
    } finally {
      fetchPublishMeta();
    }
  }, [productId, executeUnpublish, fetchPublishMeta]);

  const handleCopyAppUrl = useCallback(async () => {
    setIsCopiedToClipboard(true);
    setIsCopyLinkTooltipOpen(true);
    await navigator.clipboard.writeText(
      getSutroAppUrl(
        currentProductSubdomain.subdomainValue,
        import.meta.env.SUTRO_ENV
      )
    );
    setTimeout(() => {
      setIsCopiedToClipboard(false);
      setIsCopyLinkTooltipOpen(false);
    }, 3000);
  }, [subdomainInput]);

  const entitledToPublishOnWeb =
    !flags[PostHogFlags.PremiumFeatures] ||
    hasEntitlement(entitlements, "publish-to-mobile-web");

  /**
   * @returns {boolean} True if web publishing is allowed, false otherwise
   * - Returns true if user has web publishing entitlement and either:
   *   - No max publish limit exists
   *   - Max publish limit hasn't been reached
   *   - Or this is a republish of an existing app
   * - Returns false if:
   *   - User doesn't have web publishing entitlement
   *   - Max publish limit reached and this isn't a republish
   */
  const shouldAllowWebPublish = useMemo(() => {
    if (entitledToPublishOnWeb && publishMeta) {
      if (publishMeta.maxPublishedAppsCount === null) {
        return true;
      }

      const { currentlyPublishedAppsCount, maxPublishedAppsCount } =
        publishMeta;
      const reachedMaxPublishedAppsCount =
        currentlyPublishedAppsCount >= maxPublishedAppsCount;

      if (reachedMaxPublishedAppsCount && !isRepublishing) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }, [entitledToPublishOnWeb, isRepublishing, publishMeta]);

  const upsellMessage: null | {
    message: string;
    onClick: () => void;
    buttonLabel?: string;
  } = useMemo(() => {
    if (!publishMeta) {
      return null;
    }
    const { currentlyPublishedAppsCount, maxPublishedAppsCount } = publishMeta;

    const reachedMaxPublishedAppsCount =
      currentlyPublishedAppsCount >= (maxPublishedAppsCount ?? Infinity);

    if (getNextTierName(currentSubscription.tier.name) === "Launch") {
      if (reachedMaxPublishedAppsCount) {
        const message = isRepublishing
          ? "Want to remove our logo?"
          : "Want to publish this app?";
        return {
          message,
          onClick: onRequestUpgrade,
          buttonLabel: "Upgrade to Launch",
        };
      }
    }

    if (getNextTierName(currentSubscription.tier.name) === "Grow") {
      if (reachedMaxPublishedAppsCount) {
        const message = isRepublishing
          ? "Want to use a custom domain?"
          : "Want to publish this app?";

        return {
          message,
          onClick: onRequestUpgrade,
          buttonLabel: "Upgrade to Grow",
        };
      }
    }

    return null;
  }, [
    currentSubscription.tier.name,
    isRepublishing,
    onRequestUpgrade,
    publishMeta,
  ]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <span className="mb-[2px] text-sm font-medium">Domain</span>
        <div className="flex w-full items-center gap-[6px]">
          <div className="relative flex w-full items-center">
            <Input
              containerClassName="w-full flex-1"
              className={cn("border-border pr-28 shadow-sm", {
                "border-color-text-error": subdomainError,
              })}
              value={subdomainInput}
              onChange={(e) => {
                setSubdomainInput(e.target.value);
                setSubdomainError(undefined);
              }}
            />
            <div className="absolute right-3">
              <span className="text-text-muted text-sm">.withsutro.com</span>
            </div>
          </div>
          <TooltipProvider>
            <Tooltip
              delayDuration={500}
              open={isCopyLinkTooltipOpen}
              onOpenChange={setIsCopyLinkTooltipOpen}
            >
              <TooltipTrigger className="outline-none" tabIndex={-1}>
                <Button
                  variant="outline"
                  className="p-3 shadow-sm"
                  onClick={handleCopyAppUrl}
                >
                  <Link size={17} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {isCopiedToClipboard ? "Copied!" : "Copy link"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {isRepublishing &&
          subdomainChanged &&
          subdomainInput &&
          !subdomainError && (
            <div className="text-warning flex items-center gap-1">
              <AlertCircle size={16} />
              <span className="text-xs">Publish to update domain.</span>
            </div>
          )}

        {subdomainError && (
          <div className="text-color-text-error flex items-center gap-1">
            <AlertTriangle size={16} />
            <span className="text-xs">{subdomainError}</span>
          </div>
        )}
      </div>
      <div className="flex w-full">
        {upsellMessage && (
          <UpsellMessage
            variant="simple"
            onClickButton={upsellMessage.onClick}
            message={upsellMessage.message}
            buttonLabel={upsellMessage.buttonLabel}
          />
        )}
      </div>
      <div className="flex w-full flex-row-reverse items-center justify-between pt-2">
        <Button
          onClick={handlePublish}
          disabled={!shouldAllowWebPublish}
          testId={ButtonIds.PUBLISH_FLOW_FINISH_PUBLISH}
        >
          <div className="flex items-center gap-2">
            {publishRequestStatus === PublishRequestStatus.LOADING ? (
              <>
                <SmallLoadingSpinner />
                <span>Publishing...</span>
              </>
            ) : (
              <>
                <span>{isRepublishing ? "Publish changes" : "Publish"}</span>
              </>
            )}
          </div>
        </Button>
        {isRepublishing && (
          <Button
            variant="outline"
            className="text-color-text-error hover:text-color-text-error"
            onClick={handleUnpublish}
          >
            <div className="flex items-center gap-2">
              {unpublishRequestStatus === UnpublishRequestStatus.LOADING ? (
                <>
                  <SmallLoadingSpinner className="text-color-text-error" />
                  <span>Unpublishing...</span>
                </>
              ) : (
                <>
                  <span>Unpublish</span>
                </>
              )}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};
