import { useFormContext } from "react-hook-form";
import { DEVICE_PLATFORMS } from "sutro-common/device-platforms";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import { PulsingDot } from "~/components/ui/pulsing-dot";
import { useStudio } from "~/lib/hooks/use-studio";
import { useProfile } from "~/lib/use-profile";
import { appPublishSchema } from "~/lib/use-publish";
import { cn } from "~/lib/utils";

const platformSchema = appPublishSchema.pick({
  platform: true,
});

export const PlatformsToggle = () => {
  const publishMeta = useProfile((s) => s.publishMeta);
  const { product } = useStudio();
  const allPlatformsAndLabels = {
    [DEVICE_PLATFORMS.MOBILE_WEB]: "Web",
    [DEVICE_PLATFORMS.IOS]: "iOS",
    [DEVICE_PLATFORMS.ANDROID]: "Android",
  };

  const getPlatformValueByLabel = (label: string) => {
    return Object.keys(allPlatformsAndLabels).find(
      (key) =>
        allPlatformsAndLabels[key as keyof typeof allPlatformsAndLabels] ===
        label
    ) as DEVICE_PLATFORMS;
  };

  const isPlatformPublished = (platform: DEVICE_PLATFORMS) => {
    if (!publishMeta || !product) {
      return false;
    }

    const productInPublishMeta = publishMeta.publishedProducts.find(
      (p) => p.productId === product.id
    );

    if (!productInPublishMeta) {
      return false;
    }

    return productInPublishMeta[platform] !== undefined;
  };

  const { setValue, watch } = useFormContext<z.infer<typeof platformSchema>>();
  const platform = watch("platform");

  return (
    <div className="flex items-center gap-1">
      {Object.values(allPlatformsAndLabels).map((item) => {
        const platformValue = getPlatformValueByLabel(item);
        const isPlatformSelected = platformValue === platform;
        const isPublished = isPlatformPublished(platformValue);

        return (
          <Button
            key={item}
            variant="ghost"
            size="sm"
            className={cn(
              "hover:bg-bg-elevated text-text-muted flex items-center justify-center gap-2 rounded-md transition-colors hover:text-black",
              {
                "bg-bg-elevated text-color-text": isPlatformSelected,
              }
            )}
            onClick={() => {
              const selectedPlatform = getPlatformValueByLabel(item);

              setValue("platform", selectedPlatform as DEVICE_PLATFORMS);
            }}
          >
            <span className="text-sm font-medium">{item}</span>
            {isPublished && (
              <div className="flex">
                <PulsingDot />
              </div>
            )}
          </Button>
        );
      })}
    </div>
  );
};
