import { DEVICE_PLATFORMS } from "sutro-common";

import { PlatformsToggle } from "../platforms-toggle";
import PublishStatus from "../publish-status";
import { DomainManagement } from "./domain-management";

export const WebPublish = ({
  onRequestUpgrade,
}: {
  onRequestUpgrade: () => void;
}) => {
  return (
    <div className="flex flex-col gap-4">
      <PlatformsToggle />

      <PublishStatus platform={DEVICE_PLATFORMS.MOBILE_WEB} />

      <DomainManagement onRequestUpgrade={onRequestUpgrade} />
    </div>
  );
};
