import { DEVICE_PLATFORMS } from "sutro-common";

import { PlatformsToggle } from "../platforms-toggle";
import PublishStatus from "../publish-status";

export const IosPublish = ({
  onRequestUpgrade,
}: {
  onRequestUpgrade: () => void;
}) => {
  return (
    <div className="flex flex-col gap-4">
      <PlatformsToggle />

      <PublishStatus platform={DEVICE_PLATFORMS.IOS} />
    </div>
  );
};
