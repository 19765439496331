import { Either } from "@dancrumb/fpish";
import { OmitKeys } from "sutro-common/mapped-types/omit-keys";
import { AnyObject } from "sutro-common/object-types";

import { StudioHttpError } from "./StudioHttpError";

export type HttpResponse<D> = Either<StudioHttpError, D>;

export type FetchOptions = {
  traceName?: string;
  headers?: Headers;
  returnAs?: "json" | "text" | "blob" | "response";
  signal?: AbortSignal;
};

/**
 * This is a handy little utility that lets you build a function that takes an HttpResponse
 * and either handle the error or handle the data
 *
 */
export const either = <R>(ifError: (l: StudioHttpError) => void) => {
  return {
    or: (ifSuccess: (r: R) => void) => {
      return (response: HttpResponse<R>) => {
        response.apply(ifError, ifSuccess);
      };
    },
  };
};

/**
 * This is a relatively low-level abstraction of the Sutro API that the Studio uses, in the sense that the relevant methods are `get` and `post`, rather
 * than descriptive methods that refer to the actual API intent.
 * This can be iterated on if desired.
 *
 * What this _does_ do, is give us a singular method for sending requests. This will help with things like consistent logging and tracing. It will also simplify the calling code, since they won't need to worry about all but the most specific of configuration for a Fetch
 *
 * The key methods return an `Either` type. This is a functional type that contains a value of one of two types: the `Left` type and the `Right` type.
 *
 * Conventionally, the left type is the error type and the right type is the result type.
 *
 */
export interface Api {
  isAuthenticated: () => boolean;

  /**
   * @returns an authenticated API
   */
  authenticate(): Api;

  /**
   * Performs an HTTP GET to the provided path
   */
  get<R>(path: string): Promise<HttpResponse<R>>;
  /**
   * Performs an HTTP GET to the provided path that returns plain text
   */
  get(
    path: string,
    options: {
      payload?: URLSearchParams | null;
      options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "text" };
    }
  ): Promise<HttpResponse<string>>;
  /**
   * Performs an HTTP GET to the provided path that returns a Blob
   */
  get(
    path: string,
    options: {
      payload?: URLSearchParams | null;
      options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "blob" };
    }
  ): Promise<HttpResponse<Blob>>;
  /**
   * Performs an HTTP GET to the provided path that returns JSON
   */
  get<R>(
    path: string,
    options: { payload?: URLSearchParams; options?: FetchOptions }
  ): Promise<HttpResponse<R>>;

  /**
   * Performs an HTTP POST to the provided path without a body
   */
  post<R>(path: string, payload?: never): Promise<HttpResponse<R>>;
  /**
   * Performs an HTTP POST to the provided path that returns plain text
   */
  post(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "text" }
  ): Promise<HttpResponse<string>>;
  /**
   * Performs an HTTP POST to the provided path that returns a Blob
   */
  post(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "blob" }
  ): Promise<HttpResponse<Blob>>;
  /**
   * Performs an HTTP POST to the provided path that returns JSON
   */
  post<R>(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options?: FetchOptions
  ): Promise<HttpResponse<R>>;

  /**
   * Performs an HTTP PUT to the provided path without a body
   */
  put<R>(path: string, payload?: never): Promise<HttpResponse<R>>;
  /**
   * Performs an HTTP PUT to the provided path that returns plain text
   */
  put(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "text" }
  ): Promise<HttpResponse<string>>;
  /**
   * Performs an HTTP PUT to the provided path that returns a Blob
   */
  put(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "blob" }
  ): Promise<HttpResponse<Blob>>;
  /**
   * Performs an HTTP PUT to the provided path that returns JSON
   */
  put<R>(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options?: FetchOptions
  ): Promise<HttpResponse<R>>;

  /**
   * Performs an HTTP PATCH to the provided path without a body
   */
  patch<R>(path: string, payload?: never): Promise<HttpResponse<R>>;
  /**
   * Performs an HTTP PATCH to the provided path that returns plain text
   */
  patch(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "text" }
  ): Promise<HttpResponse<string>>;
  /**
   * Performs an HTTP PATCH to the provided path that returns a Blob
   */
  patch(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options: OmitKeys<FetchOptions, "returnAs"> & { returnAs: "blob" }
  ): Promise<HttpResponse<Blob>>;
  /**
   * Performs an HTTP PATCH to the provided path that returns JSON
   */
  patch<R>(
    path: string,
    payload: FormData | Blob | AnyObject | null,
    options?: FetchOptions
  ): Promise<HttpResponse<R>>;

  /**
   * Performs an HTTP DELETE to the provided path without a body
   */
  delete<R>(path: string, options?: FetchOptions): Promise<HttpResponse<R>>;
}
