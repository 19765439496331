import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DEVICE_PLATFORMS } from "sutro-common";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { PopoverContent } from "~/components/ui/popover";
import { useStudio } from "~/lib/hooks/use-studio";
import { useProfile } from "~/lib/use-profile";
import {
  AppleTeamType,
  appPublishSchema,
  PublishFormType,
} from "~/lib/use-publish";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";
import { useRequestUpgrade } from "~/providers/TierManagementProvider";

import { AndroidPublish } from "./android/android-publish";
import { IosPublish } from "./ios/ios-publish";
import { WebPublish } from "./web/web-publish";

export function PublishPopover() {
  const refreshUser = useProfile((s) => s.refreshUser);
  const { refreshEntitlements } = useEntitlements();
  const requestUpgrade = useRequestUpgrade();
  const fetchPublishMeta = useProfile((s) => s.fetchPublishMeta);

  const {
    workingDefinition: { appMeta },
  } = useStudio(useShallow(pick("workingDefinition", "refreshProduct")));

  const formDefaultValues = useMemo(
    () => ({
      appIconImage: undefined,
      platform: DEVICE_PLATFORMS.MOBILE_WEB,

      // TODO: The following two must come from user profile
      expoOrganizationName: "",
      expoPersonalAccessToken: "",

      expoProjectId: "",
      expoProjectSlug: "",
      privacyPolicyLink: appMeta.ppLink ?? "",
      splashScreenImage: undefined,
      termsOfServiceLink: appMeta.termsLink ?? "",

      appleTeamId: "",
      appleTeamType: AppleTeamType.INDIVIDUAL,
      ascApiKeyFile: undefined,
      ascKeyId: "",
      ascIssuerId: "",
    }),
    [appMeta]
  );

  const form = useForm<PublishFormType>({
    resolver: zodResolver(appPublishSchema),
    mode: "onSubmit",
    defaultValues: formDefaultValues,
  });

  const platform = form.watch("platform");

  useEffect(() => {
    fetchPublishMeta();
  }, [fetchPublishMeta]);

  const handleRequestUpgrade = useCallback(() => {
    const onUpgrade = async () => {
      await Promise.all([
        refreshEntitlements(),
        refreshUser(),
        fetchPublishMeta(),
      ]);
    };

    const _onCancel = () => {};

    const subHeading =
      "Select the plan that will take your product to the next level.";

    requestUpgrade({
      onUpgrade,
      onCancel: _onCancel,
      heading: "Upgrade your subscription",
      subHeading,
      context: "publish-popover",
    });
  }, [fetchPublishMeta, refreshEntitlements, refreshUser, requestUpgrade]);

  return (
    <PopoverContent className="min-w-[458px] rounded-2xl p-6" align="end">
      <FormProvider {...form}>
        {platform === DEVICE_PLATFORMS.MOBILE_WEB && (
          <WebPublish onRequestUpgrade={handleRequestUpgrade} />
        )}
        {platform === DEVICE_PLATFORMS.IOS && (
          <IosPublish onRequestUpgrade={handleRequestUpgrade} />
        )}
        {platform === DEVICE_PLATFORMS.ANDROID && (
          <AndroidPublish onRequestUpgrade={handleRequestUpgrade} />
        )}
      </FormProvider>
    </PopoverContent>
  );
}
