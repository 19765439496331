import {
  Bug,
  GitGraph,
  GitPullRequestCreateArrow,
  ListTree,
  Server,
  Settings,
  Workflow,
} from "lucide-react";
import { useCallback, useMemo, useState } from "react";

import graphQl from "~/assets/graphql.svg";
import { Button } from "~/components/ui/button";
import { DialogContent, DialogTitle } from "~/components/ui/dialog";
import { useAppConfig } from "~/lib/hooks/use-app-config";
import useBreakpoints from "~/lib/hooks/use-breakpoint";
import { DevOptions, useDevOptions } from "~/lib/hooks/use-dev-options";
import { cn } from "~/lib/utils";

import { useStudio } from "../../../lib/hooks/use-studio";
import {
  DefinitionPlayground,
  PostPluginDefinitionPlayground,
} from "./definition-playground";
import { DevOptionsView } from "./dev-options-view";
import { GraphViewer } from "./graph-viewer";
import { TreeViewer } from "./tree-viewer";

export const DevToolsNavButton = ({ onClick }: { onClick: () => void }) => {
  const breakpoints = useBreakpoints();
  const { appConfig } = useAppConfig();

  return (
    <Button
      variant="secondary"
      className={cn("flex cursor-pointer flex-row gap-2", {
        hidden: !appConfig.isDev && !appConfig.isStaging,
      })}
      onClick={onClick}
    >
      <Bug className="size-4 text-red-500" />
      {breakpoints.lg && "DevTools"}
    </Button>
  );
};

const DeploymentInfo = () => {
  return (
    <div className="flex size-full flex-col p-4">
      <div className="flex items-center">
        <h2 className="mr-2 font-bold">Last deployed: </h2>
        <span>Fri, 16 Aug 2024 17:18:46 GMT 72323a2</span>
      </div>
    </div>
  );
};

const tabs: {
  label: string;
  value: string;
  // Not amazing typing here but it works
  icon: typeof GitGraph;
  component: React.ComponentType;
  shouldShow?: (devOptions: DevOptions) => boolean;
}[] = [
  {
    label: "Definition",
    value: "definition",
    icon: GitGraph,
    component: DefinitionPlayground,
  },
  {
    label: "Post-Plugin Definition",
    value: "postPluginDefinition",
    icon: GitPullRequestCreateArrow,
    shouldShow: (devOptions) => devOptions.shouldGetPostPluginDefinition,
    component: PostPluginDefinitionPlayground,
  },
  {
    label: "Graph",
    value: "graph",
    icon: Workflow,
    component: GraphViewer,
  },
  {
    label: "Tree",
    value: "tree",
    icon: ListTree,
    component: TreeViewer,
  },
  {
    label: "Deployment",
    value: "deployment",
    icon: Server,
    component: DeploymentInfo,
  },
  {
    label: "Dev Options",
    value: "options",
    icon: Settings,
    component: DevOptionsView,
  },
] as const;

export const DEV_TOOLS_DIALOG_ID = "showDevTools";

export function DevToolsDialogContent() {
  const apiUrl = useStudio((s) => s.sClientScript?.apiUrl);
  const [selectedTab, setSelectedTab] =
    useState<(typeof tabs)[number]["value"]>("definition");

  const { devOptions } = useDevOptions();

  const filteredTabs = useMemo(() => {
    return tabs.filter(
      (tab) => tab.shouldShow === undefined || tab.shouldShow(devOptions)
    );
  }, [devOptions]);

  const openGqlStudio = useCallback(() => {
    if (apiUrl) {
      window.open(apiUrl, "_gqlStudio");
    }
  }, [apiUrl]);

  return (
    <DialogContent className="flex h-[80vh] min-w-[80vw] flex-col overflow-hidden">
      <DialogTitle>🐞 Sutro DevTools</DialogTitle>
      <div className="mt-2 flex size-full flex-1">
        <div className="flex flex-col gap-2">
          {filteredTabs.map((tab) => {
            const isSelected = selectedTab === tab.value;
            return (
              <Button
                key={tab.value}
                variant="ghost"
                className={cn("justify-start transition hover:bg-gray-200", {
                  "bg-gray-200": isSelected,
                })}
                onClick={() => setSelectedTab(tab.value)}
              >
                <tab.icon className="mr-2 size-4" />
                {tab.label}
              </Button>
            );
          })}
          <Button
            variant="ghost"
            className="justify-start transition hover:bg-gray-200"
            onClick={openGqlStudio}
          >
            <img src={graphQl} className="mr-2 size-4" alt="" /> Open GQL
          </Button>
        </div>
        <div className="flex-1">
          {(() => {
            const Component = tabs.find(
              (tab) => tab.value === selectedTab
            )?.component;
            return Component ? <Component /> : null;
          })()}
        </div>
      </div>
    </DialogContent>
  );
}
