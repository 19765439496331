import { DEVICE_PLATFORMS } from "sutro-common";
import { removeProtocolFromHostName } from "sutro-common/hostnames";

import { PulsingDot } from "~/components/ui/pulsing-dot";
import { useStudio } from "~/lib/hooks/use-studio";
import { useProfile } from "~/lib/use-profile";
import { cn } from "~/lib/utils";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

const NotPublishedText = () => {
  return <span className="text-sm font-medium">Not yet published</span>;
};

const PublishedToSubdomainText = ({ webAppURL }: { webAppURL: string }) => {
  return (
    <span className="text-color-text-success-strong text-sm font-medium">
      Published to{" "}
      <a
        href={webAppURL}
        className="text-color-text font-semibold hover:underline"
        target="_blank"
        rel="noreferrer"
      >
        {removeProtocolFromHostName(webAppURL)}
      </a>
    </span>
  );
};

const WebPublishStatus = () => {
  const publishMeta = useProfile((s) => s.publishMeta);
  const { product } = useStudio();
  const { currentSubscription } = useEntitlements();

  if (!publishMeta || !product) {
    return (
      <div className="flex flex-col rounded-lg p-4 shadow-sm bg-bg-elevated">
        <NotPublishedText />
      </div>
    );
  }

  const productInPublishMeta = publishMeta.publishedProducts.find(
    (p) => p.productId === product.id
  );

  if (
    !productInPublishMeta ||
    !productInPublishMeta[DEVICE_PLATFORMS.MOBILE_WEB]
  ) {
    return (
      <div className="flex flex-col rounded-lg p-4 shadow-sm bg-bg-elevated">
        <NotPublishedText />
        <span className={"text-text-muted mt-1 text-xs"}>
          {publishMeta?.currentlyPublishedAppsCount}/
          {publishMeta?.maxPublishedAppsCount === null
            ? "?"
            : publishMeta?.maxPublishedAppsCount}{" "}
          apps published on your {currentSubscription.tier.name} plan.
        </span>
      </div>
    );
  }

  const isPublishedOnWeb =
    productInPublishMeta[DEVICE_PLATFORMS.MOBILE_WEB] !== undefined;

  return (
    <div
      className={cn(
        "flex items-start rounded-lg p-4 shadow-sm bg-bg-elevated",
        {
          "bg-success-muted": isPublishedOnWeb,
        }
      )}
    >
      {isPublishedOnWeb && (
        <div className="flex pr-3 pt-[6px]">
          <PulsingDot />
        </div>
      )}
      <div className="flex flex-col">
        {isPublishedOnWeb ? (
          <PublishedToSubdomainText
            webAppURL={
              productInPublishMeta[DEVICE_PLATFORMS.MOBILE_WEB].webAppURL
            }
          />
        ) : (
          <NotPublishedText />
        )}
        <span
          className={cn("text-text-muted mt-1 text-xs", {
            "text-text-success": isPublishedOnWeb,
          })}
        >
          {publishMeta?.currentlyPublishedAppsCount}/
          {publishMeta?.maxPublishedAppsCount === null
            ? "?"
            : publishMeta?.maxPublishedAppsCount}{" "}
          apps published on your {currentSubscription.tier.name} plan.
        </span>
      </div>
    </div>
  );
};

const PublishStatus = ({ platform }: { platform: DEVICE_PLATFORMS }) => {
  if (platform === DEVICE_PLATFORMS.MOBILE_WEB) {
    return <WebPublishStatus />;
  }

  return (
    <div className="flex flex-col rounded-lg p-4 shadow-sm bg-bg-elevated">
      <NotPublishedText />
    </div>
  );
};

export default PublishStatus;
